import Base from "../base";

/** 月次報告書AI自動作成設定 */
export interface AiMonthReportSetting extends Base {
  /** ID */
  id: number;
  /** プロンプトテンプレートID */
  prompt_template_id: number;
  /** プロンプトテンプレートID(別添自動作成) */
  betten_prompt_template_id: number;
  /** 1:作成オプションに「本日の状態（バイタル）」を追加 */
  add_vital_option: number;
  /** 作成オプション「本日の状態（バイタル）」デフォルト値 */
  default_vital_option: number;
  /** 1:作成オプションに「観察項目」を追加 */
  add_observation_option: number;
  /** 作成オプション「観察項目」デフォルト値 */
  default_observation_option: number;
  /** 1:作成オプションに「最新報告月の文章スタイル反映」を追加 */
  add_pre_month_report_option: number;
  /** 作成オプション「最新報告月の文章スタイル反映」デフォルト値 */
  default_pre_month_report_option: number;
  /** 最終更新者ID */
  editor_admin_id: number;
}

export const DefaultAiMonthReportSetting = (): AiMonthReportSetting => ({
  id: 0,
  prompt_template_id: 0,
  betten_prompt_template_id: 0,
  add_vital_option: 0,
  default_vital_option: 0,
  add_observation_option: 0,
  default_observation_option: 0,
  add_pre_month_report_option: 0,
  default_pre_month_report_option: 0,
  editor_admin_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});
